import React from "react";
import logo from "./logo.svg";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Services, { ServiceDetails } from "./components/Services/Services";
import AboutUs from "./components/About/AboutUs";
import ContactUs from "./components/Contact/ContactUs";
import ScheduleConsultation from "./components/Contact/Schedule";
import Raffle from "./components/Raffle/Raffle";

export const myTheme = createTheme({
  palette: {
    primary: {
      main: "#1367cf",
    },
    secondary: {
      main: "#197352",
    },
    text: {
      secondary: "#212121",
    },
  },
  typography: {
    //fontFamily: "Quicksand",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
});

function App() {
  return (
    <ThemeProvider theme={myTheme}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:id" element={<ServiceDetails />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ScheduleConsultation />} />
          <Route path="/schedule" element={<ScheduleConsultation />} />
          <Route path="/:accountId/:raffleId/:result?" element={<Raffle />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
