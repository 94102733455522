import { PaymentMethod, RafflePurchaseType, RaffleType } from "./types";
import { initializeApp } from "firebase/app";
import {
  collection,
  getDocs,
  getFirestore,
  getDoc,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBVCCcHMQm1Wn_IHWyK4MkVI9rRKctQcQQ",
  authDomain: "rafflejoin-4d140.firebaseapp.com",
  projectId: "rafflejoin-4d140",
  storageBucket: "rafflejoin-4d140.appspot.com",
  messagingSenderId: "1065458802330",
  appId: "1:1065458802330:web:76912a894ac49453de0923",
  measurementId: "G-J0RQ6ZFCTW",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getRaffleData = async (
  accountId: string,
  raffleId: string
): Promise<RaffleType> => {
  const docSnap = await getDoc(
    doc(db, `accounts/${accountId}/raffles/${raffleId}`)
  );
  if (docSnap.exists()) {
    const data = docSnap.data();
    return {
      ...data,
      ...{ startDate: data.startDate.toDate(), endDate: data.endDate.toDate() },
    } as RaffleType;
  } else {
    // docSnap.data() will be undefined in this case
    return Promise.reject("not found");
  }
};

export const submitRaffleEntry = async (
  accountId: string,
  raffleId: string,
  data: RafflePurchaseType
) => {
  const docRef = doc(collection(db, `accounts/${accountId}/raffleEntries`));

  const dataX = {
    ...data,
    ...{
      id: docRef.id,
      timestamp: serverTimestamp(),
      raffleId,
      paid: false,
      email_lc: data.email.toLowerCase(),
    },
  };
  try {
    await setDoc(docRef, dataX);
    console.log(dataX);
  } catch (e) {
    console.log(e);
  }
};
