import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Avatar,
  Box,
  CircularProgress,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
} from "@mui/material";
import "./Raffle.css"; // Import the CSS file for styling
import {
  RaffleType,
  RafflePurchaseType,
  PaymentMethod,
} from "../../services/types";
import { getRaffleData, submitRaffleEntry } from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";

const Raffle = () => {
  const { accountId, raffleId, result } = useParams();
  const nav = useNavigate();

  const [busy, setBusy] = useState(false);
  const [raffleData, setRaffleData] = useState<RaffleType>();
  const [formData, setFormData] = useState<RafflePurchaseType>({
    name: "",
    email: "",
    numberOfTickets: 1,
    paymentMethod: PaymentMethod.Cash,
  });
  const [emailError, setEmailError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (accountId && raffleId) {
      setBusy(true);
      getRaffleData(accountId, raffleId)
        .then(setRaffleData)
        .finally(() => {
          setBusy(false);
        });
    }
  }, [accountId, raffleId]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmailError(!isValidEmail(value));
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to backend
    setOpenDialog(true);
    // Reset form after submission
    // setFormData({
    //   name: "",
    //   email: "",
    //   numberOfTickets: 1,
    //   paymentMethod: PaymentMethod.Cash,
    // });
  };

  const handleSubmit4Real = (e: any) => {
    e.preventDefault();
    setBusy(true);
    setOpenDialog(false);
    submitRaffleEntry(accountId ?? "", raffleId ?? "", {
      ...formData,
      name: formData.name.trim(),
      email: formData.email.trim(),
    }).then(() => {
      setBusy(false);
      setFormData({
        name: "",
        email: "",
        numberOfTickets: 1,
        paymentMethod: PaymentMethod.Cash,
      });
      nav(`/${accountId}/${raffleId}/success`);
    });
    // Reset form after submission
    // setFormData({
    //   name: "",
    //   email: "",
    //   numberOfTickets: 1,
    //   paymentMethod: PaymentMethod.Cash,
    // });
  };

  const isValidEmail = (email: string) => {
    // Basic email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const methodToText = (m: PaymentMethod) => {
    if (m === PaymentMethod.Cash) return "Cash";
    if (m === PaymentMethod.Zelle) return "Zelle";
    if (m === PaymentMethod.Paypal) return "Paypal";
    if (m === PaymentMethod.Venmo) return "Venmo";
    return "Check";
  };
  //if (bu)
  if (busy) return <CircularProgress />;

  if (!raffleData) return null;
  const { title, description } = raffleData;
  const canSubmit =
    !emailError &&
    formData.email &&
    formData.name.length > 2 &&
    formData.numberOfTickets > 0;
  return (
    <Container
      sx={{
        padding: 2,
        //height: "100vh",
        textAlign: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "50%" } }}>
        {busy && <CircularProgress color="inherit" size={32} />}
        <Typography variant="h4" sx={{ display: { md: "flex", xs: "none" } }}>
          {title}
        </Typography>
        <Typography variant="h5" sx={{ display: { md: "none", xs: "flex" } }}>
          {title}
        </Typography>

        {raffleData.bannerImage && (
          <Box
            sx={{
              mt: 2,
              width: "100%",
              height: "200px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url('${raffleData.bannerImage}')`,
            }}
          />
        )}
        <Box>
          <Typography
            variant="body1"
            sx={{ mt: 2, mb: 2, textAlign: "left", fontWeight: "normal" }}
          >
            {description}
          </Typography>
        </Box>
        {/* <Typography variant="body1">Cost: ${ticketPrice} per entry</Typography> */}

        {!result && (
          <form onSubmit={handleSubmit}>
            <TextField
              label="Enter your name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Enter your Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="dense"
              error={emailError}
              helperText={emailError ? "Please enter a valid email" : ""}
            />
            <TextField
              label="How many tickets do you want?"
              name="tickets"
              type="number"
              value={
                formData.numberOfTickets <= 0 ? "" : formData.numberOfTickets
              }
              onChange={(e) => {
                setFormData((p) => ({
                  ...p,
                  numberOfTickets: Number(e.target.value),
                }));
              }}
              fullWidth
              margin="dense"
              helperText={`Total Cost: $${
                formData.numberOfTickets * raffleData.ticketPrice
              }`}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="payment-method-label">
                How do you plan to Pay?
              </InputLabel>
              <Select
                labelId="payment-method-label"
                name="paymentMethod"
                value={formData.paymentMethod}
                onChange={handleChange}
                label="How do you plan to Pay?"
              >
                {raffleData.paymentChoices.map((x) => (
                  <MenuItem value={x.method} key={x.method}>
                    {methodToText(x.method)}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" sx={{ mt: 2 }}>
                Payment Instructions:{" "}
                {raffleData.paymentChoices.find(
                  (x) => x.method === formData.paymentMethod
                )?.info ?? ""}
              </Typography>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!canSubmit}
            >
              Enter Raffle
            </Button>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Raffle Confirmation</DialogTitle>
              <DialogContent>
                You will now be entered into the raffle drawing with{" "}
                <b>{formData.numberOfTickets}</b> chance
                {formData.numberOfTickets === 1 ? "" : "s"} of winning. Note:
                You must submit payment of{" "}
                <b>${formData.numberOfTickets * raffleData.ticketPrice}</b>{" "}
                prior to raffle draw date of{" "}
                <b>{raffleData.endDate.toLocaleDateString()}</b> in order to be
                eligible in the raffle drawing. <br />
                Payment Instructions:{" "}
                {raffleData.paymentChoices.find(
                  (x) => x.method === formData.paymentMethod
                )?.info ?? ""}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit4Real} color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
        {result && (
          <>
            <Typography sx={{ fontWeight: "light", mt: 5, mb: 5 }}>
              Thank you for participating. You will be notified via your email
              about any Raffle Updates
            </Typography>
            <Button onClick={() => nav(-1)}>Go back</Button>
          </>
        )}
      </Box>
      {/* </Paper> */}
    </Container>
  );
};

export default Raffle;
