import React from "react";
import { Container, Typography, Avatar, Box } from "@mui/material";
import "./AboutUs.css"; // Import the CSS file for styling

const AboutUs = () => {
  return (
    <Container className="about-us-container" sx={{ padding: 2 }}>
      <Box>
        {/* <Paper elevation={3} className="about-us-paper"> */}
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          color="secondary"
          sx={{ textAlign: "center", m: 5 }}
        >
          About RaffleJoin
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", m: 5 }}>
          Raffle Join is a simple website for hosting raffle drawings for your
          organization or cause
        </Typography>
      </Box>
      {/* </Paper> */}
    </Container>
  );
};

export default AboutUs;
