export enum PaymentMethod {
  Cash,
  Zelle,
  CashApp,
  Venmo,
  Check,
  Paypal,
}

export interface PaymentChoice {
  method: PaymentMethod;
  info: string;
}

export interface RaffleType {
  id: string;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  ticketPrice: number;
  logo?: string;
  bannerImage?: string;
  paymentChoices: PaymentChoice[];
}

export interface RafflePurchaseType {
  name: string;
  email: string;
  numberOfTickets: number;
  paymentMethod: PaymentMethod;
}
