import React from "react";
import "./HeroSection.css"; // Import the CSS file for styling
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const nav = useNavigate();
  return (
    <div className="hero-section">
      {/* Background Image */}
      <div className="hero-image"></div>

      {/* Text Overlay */}
      <div className="hero-text">
        <h1>Welcome to RaffleJoin</h1>
        <p>The simplest way to run a raffle for your organization or cause</p>
        <p>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => {}}
          >
            How does it work?
          </Button>
        </p>
        <p>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={() => {}}
          >
            Get Started
          </Button>
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
